@import './vendors/tailwind/tailwind.css';
@font-face {
  font-family: 'Great Vibes';
  src: url(/assets/fonts/GreatVibes-Regular.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Playfair Display';
  src: url(/assets/fonts/PlayfairDisplay-Regular.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Billion Dreams';
  src: url(/assets/fonts/BillionDreams_PERSONAL.ttf) format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Lime Light';
  src: url(/assets/fonts/Limelight-Regular.ttf) format('truetype');
  font-display: swap;
}

@font-face {
  font-family: 'Limelight';
  src: url('https://fonts.googleapis.com/css2?family=Limelight&display=swap');
  font-display: swap;
}

fa-icon svg {
  display: inline-block;
  font-size: inherit;
  height: 1em;
}

/* Scroll bar */
/* width */
fc-root ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  position: absolute;
}
/* Track */
fc-root ::-webkit-scrollbar-track {
  box-shadow: transparent;
  border-radius: 8px;
}
/* Handle */
fc-root ::-webkit-scrollbar-thumb {
  @apply rounded-md bg-primary-dark;
}
fc-root ::-webkit-scrollbar-thumb:hover {
  @apply rounded-md bg-primary-md-dark;
}
